import { useEffect, useState } from 'react';

import { useLaunchDarklyContext } from './LaunchDarklyContext';

export const useFeature = (key: string) => {
  const { ldClient, ldEnabled } = useLaunchDarklyContext();
  const [ready, setReady] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    (async () => {
      if (!ldClient) {
        return;
      }
      const active = await ldClient.variation(key);
      setReady(true);
      setActive(active);
    })();
  }, [key, ldClient]);

  return {
    ldEnabled,
    ready,
    active,
  };
};
