import React, { FC } from 'react';

export const ActionCard: FC<{
  index: number;
  text: string;
  title: string;
}> = ({ index, text, title }) => {
  return (
    <div className="ActionCard">
      <div className="ActionCard__Number">
        <span>{toStep(index)}</span>
      </div>
      <div className="ActionCard__Title">{title}</div>
      <div className="ActionCard__Text">{text}</div>
    </div>
  );
};

function toStep(index: number) {
  if (index < 10 || index > -10) {
    return `0${index}`;
  }
  return index.toString();
}
