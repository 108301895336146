import React, { FC } from 'react';

import { FormInfo, isGroupBenefitsDomain } from '../../utils/util';

import { LegalCallToAction } from '../LegalCallToAction';
import { FormsLibrary } from '../FormsLibrary';
import { EnrollmentCard } from '../EnrollmentCard';
import { LegalDisclaimer } from '../LegalDisclaimer';

export const FormListBodyDesktop: FC<{
  showCta: boolean;
  showDisclaimer: boolean;
  onFormClick: (item: FormInfo) => void;
}> = ({ showCta, showDisclaimer, onFormClick }) => {
  return (
    <div className="FormsListBodyDesktop">
      <div className="FormsListBodyDesktop__LeftColumn">
        <FormsLibrary handleClick={onFormClick} />
      </div>
      <div className="FormsListBodyDesktop__RightColumn">
        <>{isGroupBenefitsDomain() && <EnrollmentCard />}</>
        {showCta && <LegalCallToAction />}
        {showDisclaimer && <LegalDisclaimer />}
      </div>
    </div>
  );
};
