import React, { FC } from 'react';
import { Heading, Label } from '@legalshield/adonis-ux-framework';

export const PageHeader: FC<{ title?: string; description?: string }> = ({ title, description }) => {
  if (!title && !description) {
    return null;
  }

  return (
    <div className="Page__Header">
      {title && <Heading as="T39" text={title} />}
      {description && <Label labelSize="medium" text={description} />}
    </div>
  );
};
