import React, { useState } from 'react';

import { FormInfo, isGroupBenefitsDomain } from '../../utils/util';
import { AuthModal, PdfImage } from '../../components';
import { useFirebase } from '../../context/FirebaseContext';

import { Page } from '../Page';

import './FormsList.scss';
import { useWindowContext } from '../WindowContext';
import { useLegalCallToAction } from '../LegalCallToAction';

import { FormListBodyMobile } from './FormListBodyMobile';
import { FormListBodyDesktop } from './FormListBodyDesktop';

export const FormsList = () => {
  const [modal, setModal] = useState<boolean>(false);
  const [selectedForm, setSelectedForm] = useState<FormInfo>();
  const [showAuthModal, setShowAuthModal] = useState<boolean>(false);
  const firebase = useFirebase();
  const { ready, active } = useLegalCallToAction();
  const { mobile } = useWindowContext();

  const isAuthenticated = authNPayload != null && authNPayloadValid;

  const displayModals = () => {
    if (isAuthenticated || isGroupBenefitsDomain()) {
      setModal(true);
      setShowAuthModal(false);
    } else {
      setShowAuthModal(true);
    }
  };

  ads_set_title(string_table.TITLE);

  const handleFormClick = (item: FormInfo) => {
    let formName = item.name;
    if (item.state) {
      formName = formName.concat(` - ${item.state}`);
    }
    firebase.fireEvent('click_form', { name: formName });
    setSelectedForm(item);
    displayModals();
  };

  const showQuestionnaire = ready && active && !mobileAgent;

  return (
    <Page title={string_table.FORM_LIST_TITLE} description={string_table.FORM_LIST_DESCRIPTION}>
      {mobile ? (
        <FormListBodyMobile showCta={ready && active} showDisclaimer={ready && !active} onFormClick={handleFormClick} />
      ) : (
        <FormListBodyDesktop showCta={ready && active} showDisclaimer={ready && !active} onFormClick={handleFormClick} />
      )}
      {modal && selectedForm && <PdfImage form={selectedForm} showQuestionnaire={showQuestionnaire} setModal={setModal} />}
      {showAuthModal && <AuthModal closeFunction={() => setShowAuthModal(false)} />}
    </Page>
  );
};

function isMobileUserAgent() {
  // @ts-expect-error some of these properties are deprecated
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase());
}

const mobileAgent = isMobileUserAgent();
