import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { FormsList } from './components';
import './App.css';
import { ScrollToTop } from './hooks/ScrollToTop';
import { FirebaseContainer } from './context/FirebaseContainer';
import { LaunchDarklyProvider } from './components/LaunchDarkly';
import { WindowProvider } from './components/WindowContext';

// Explicitly initializing Launch Darkly just when authenticated.
// We need to keep our monthly active user count as low as we can.
// Forms is used by anonymous users but this experiment is just for signed-in users.

export const App = () => {
  return (
    <FirebaseContainer>
      <LaunchDarklyProvider onlyAuthenticated>
        <WindowProvider>
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<FormsList />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </ScrollToTop>
        </WindowProvider>
      </LaunchDarklyProvider>
    </FirebaseContainer>
  );
};
