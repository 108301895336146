import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import { getLsEnv } from './utils/util';

const firebaseConfigs = {
  dev: {
    apiKey: 'AIzaSyCDpFdetG08QScN-rBU5Ahm7XoqJ2WKEDg',
    authDomain: 'pplsi-products-dev-dfe3f.firebaseapp.com',
    databaseURL: 'https://pplsi-products-dev-dfe3f-default-rtdb.firebaseio.com',
    projectId: 'pplsi-products-dev-dfe3f',
    storageBucket: 'pplsi-products-dev-dfe3f.appspot.com',
    messagingSenderId: '672690128438',
    appId: '1:672690128438:web:3e3ed14a42ca65f9a97a97',
    measurementId: 'G-1JWSXNLHTK',
  },
  prod: {
    apiKey: 'AIzaSyBQXQjKumUJ6FXd-Vyjysgg9MKQoAZyt1Y',
    authDomain: 'pplsi-products-dev.firebaseapp.com',
    databaseURL: 'https://pplsi-products-dev-default-rtdb.firebaseio.com',
    projectId: 'pplsi-products-dev',
    storageBucket: 'pplsi-products-dev.appspot.com',
    messagingSenderId: '822455943427',
    appId: '1:822455943427:web:e1ec13d3061d1168dfa936',
    measurementId: 'G-G1KY1H5E29',
  },
};

const firebaseConfig = getLsEnv() == 'dev' ? firebaseConfigs.dev : firebaseConfigs.prod;

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const firebaseAnalytics = getAnalytics(app);
if (jwt_payload && jwt_payload.act && jwt_payload.act.sub_type.toLowerCase() == 'internal') {
  setUserProperties(firebaseAnalytics, {
    user_id: jwt_payload.sub,
    realm: jwt_payload.sub_type,
    impersonator_id: jwt_payload.act.sub,
    impersonator_realm: jwt_payload.act.sub_type,
  });
} else if (jwt_payload) {
  setUserProperties(firebaseAnalytics, { user_id: jwt_payload.sub, realm: jwt_payload.sub_type });
}

export const fireEvent = (eventName: string, eventParams?: { [p: string]: any } | undefined) => {
  logEvent(firebaseAnalytics, eventName, eventParams);
};
