import { useEffect } from 'react';

import { fireEvent } from '../../firebase';

import { useFeature } from '../LaunchDarkly';

export function useLegalCallToAction() {
  const feature = useFeature('legalCallToAction');
  const { ldEnabled, active, ready } = feature;

  useEffect(() => {
    if (!ldEnabled) {
      fireEvent('legal_call_to_action_ineligible');
      return;
    }

    if (!ready) {
      return;
    }

    if (active) {
      fireEvent('legal_call_to_action_on');
    } else {
      fireEvent('legal_call_to_action_off');
    }
  }, [ldEnabled, active, ready]);

  return feature;
}
