import React from 'react';

import './LegalCallToAction.scss';
import { ActionCard } from './ActionCard';

export const LegalCallToAction = () => {
  return (
    <div className="LegalCallToAction">
      {instructions.map(({ text, title }, index) => (
        <ActionCard index={index + 1} text={text} title={title} key={index} />
      ))}
      <div className="LegalCallToAction__Spacer">&nbsp;</div>
    </div>
  );
};

const instructions = [
  {
    title: string_table.LEGAL_CTA_00_TITLE,
    text: string_table.LEGAL_CTA_00_TEXT,
  },
  {
    title: string_table.LEGAL_CTA_01_TITLE,
    text: string_table.LEGAL_CTA_01_TEXT,
  },
  {
    title: string_table.LEGAL_CTA_02_TITLE,
    text: string_table.LEGAL_CTA_02_TEXT,
  },
];
