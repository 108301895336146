import React, { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';

export const WindowContext = createContext({
  mobile: false,
});

export const WindowProvider: FC<{ children?: ReactNode | ReactNode[]; breakpoint?: number }> = ({
  children,
  breakpoint,
}) => {
  const [mobile, setMobile] = useState(isMobile(breakpoint));

  useEffect(() => {
    const resize = () => {
      setMobile(isMobile(breakpoint));
    };

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [breakpoint]);

  return (
    <WindowContext.Provider
      value={{
        mobile,
      }}
    >
      {children}
    </WindowContext.Provider>
  );
};

export const useWindowContext = () => useContext(WindowContext);

function isMobile(breakpoint = 1024) {
  const width = window.innerWidth;
  return width < breakpoint;
}
