import React, { FC } from 'react';

import { FormInfo, isGroupBenefitsDomain } from '../../utils/util';

import { LegalCallToAction } from '../LegalCallToAction';
import { FormsLibrary } from '../FormsLibrary';
import { EnrollmentCard } from '../EnrollmentCard';
import { LegalDisclaimer } from '../LegalDisclaimer';
import { HorizontalScroll } from '../HorizontalScroll';

export const FormListBodyMobile: FC<{
  showCta: boolean;
  showDisclaimer: boolean;
  onFormClick: (item: FormInfo) => void;
}> = ({ showCta, showDisclaimer, onFormClick }) => {
  return (
    <div className="FormListBodyMobile">
      {showCta && (
        <HorizontalScroll>
          <LegalCallToAction />
        </HorizontalScroll>
      )}
      <FormsLibrary handleClick={onFormClick} />
      {isGroupBenefitsDomain() && <EnrollmentCard />}
      {showDisclaimer && <LegalDisclaimer />}
    </div>
  );
};
