import React, { FC, ReactNode } from 'react';

import { Footer, Layout } from '@legalshield/adonis-ux-framework';
import { PageHeader } from './PageHeader';
import './Page.scss';

export const Page: FC<{
  title?: string;
  description?: string;
  children?: ReactNode | ReactNode[];
  noFooter?: boolean;
  noPadding?: boolean;
}> = ({ title, description, children, noFooter, noPadding }) => {
  return (
    <Layout classNames={[noPadding ? undefined : 'lsux-layout-padding']} variant="none">
      <PageHeader title={title} description={description} />
      <>{children}</>
      <>
        {!noFooter && (
          <Footer align="right" classNames={[noPadding ? undefined : 'lsux-layout-padding']} htmlContent={footerHtml} />
        )}
      </>
    </Layout>
  );
};
