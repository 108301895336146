import React, { createContext, useEffect, useState, FC, ReactNode, useCallback, useContext } from 'react';
import { initialize, LDClient } from 'launchdarkly-react-client-sdk';
import { getLsEnv } from '../../utils/util';

const env = getLsEnv();
const launchDarklyClientIds: { [key: string]: string } = {
  dev: '6736559e82caed08409aefb3',
  uat: '6736558f30431f0822348dd4',
  prod: '6736558f30431f0822348dd5',
};

export const LaunchDarklyContext = createContext({
  ldEnabled: false,
  ldClient: undefined as LDClient | undefined,
});

export const LaunchDarklyProvider: FC<{
  onlyAuthenticated?: boolean;
  children: ReactNode | ReactNode[];
}> = ({ onlyAuthenticated, children }) => {
  const authenticated = authNPayload != null && authNPayloadValid;
  const ldEnabled = !onlyAuthenticated || authenticated;
  const [ldClient, setLdClient] = useState<LDClient>();

  const init = useCallback(async () => {
    const ldClient = initialize(launchDarklyClientIds[env], {
      kind: 'user',
      key: jwt_payload.sub,
    });
    await ldClient.waitForInitialization(5);
    setLdClient(ldClient);
  }, []);

  useEffect(() => {
    if (ldEnabled) {
      init();
    }
  }, [ldEnabled, init]);

  return (
    <LaunchDarklyContext.Provider
      value={{
        ldEnabled,
        ldClient,
      }}
    >
      {children}
    </LaunchDarklyContext.Provider>
  );
};

export const useLaunchDarklyContext = () => useContext(LaunchDarklyContext);
